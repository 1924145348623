import React, { useState, useEffect } from 'react';
import "./AssociationPage.css";
const AssociationPage = () => {




  const [jsonData, setJsonData] = useState(null);
  useEffect(() => {
    fetch('/data.json') // Assuming data.json is in the public folder
      .then(response => response.json())
      .then(data => setJsonData(data))
      .catch(error => console.error('Error fetching JSON:', error));
  }, []);

  if (!jsonData) {
    return <div>Loading...</div>;
  }





  return (

    <div className="card-container">
      {/* <div className="container">  MOVE IT UPWARD*/}
      <a href={jsonData.this_site}>
        <img
          src="abshyd1.webp"
          alt="Association Logo"
          className="card-logo"
        />{" "}
      </a>
      <br />
      <br />
      <br />
      <div className="footer-container">
        <p className="footer-text">
          {jsonData.title}            <a href={jsonData.our_site} rel="noreferrer" target="_blank">
            {jsonData.team}            </a>
        </p>
      </div>
      {/*</div>*/}
    </div>

  );
};

export default AssociationPage;
