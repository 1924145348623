import React, { useState, useEffect } from 'react';
import "./Membership.css";

const Membership = () => {



  const [jsonData, setJsonData] = useState(null);
  useEffect(() => {
    fetch('/data.json') // Assuming data.json is in the public folder
      .then(response => response.json())
      .then(data => setJsonData(data))
      .catch(error => console.error('Error fetching JSON:', error));
  }, []);

  if (!jsonData) {
    return <div>Loading...</div>;
  }







  return (
    <section id="membership" className="section mt-5 pt-5">
      <h1 className="poetsen-one-regular">Membership Information</h1>
      {/*      <div className="container">
       */}{" "}
      <div className="card-container">
        <div className="card-content">
          <div className="inter-400">
            <br />
            <br />
            <h5>Eligibility Criteria</h5>
            <br />
            <p>
              ✦ Indians who have studied or trained in the UK for not less than
              three months or who have visited the UK for education or training
              for not less than a week under the British Council auspices.
            </p>
            <p>✦ Indians with a deep UK connection </p>
            <br />
            <br />
            <h5>Membership Benefits</h5>
            <br />
            <p>✦ Be part of a community known for achievements.</p>
            <p>
              ✦ Build personal and professional networks with UK connection.
            </p>
            <p>✦ Access to communities and fora of the association.</p>
            <p>✦ Privileged access to events in Hyderabad.</p>
            <br />
            <br />
            <h5>Membership Fees</h5>
            <br />
            <p>✦ Life membership: ₹ 5000</p>
            <br />
            <br />
            <h5>Download Application Form</h5>
            <br />
            <a
              className="btn btn-success"
              href="association_of_british_scholar_application_new.pdf"
              download="association_of_british_scholar_application_new.pdf"
            >
              Download
            </a>
            &nbsp; &nbsp; &nbsp;
            <a
              className="btn btn-primary"
              href={jsonData.form}
            >
              Apply Online
            </a>
          </div>
        </div>
      </div>
      {/*      </div>
       */}{" "}
    </section>
  );
};

export default Membership;
