import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <section id="home" className="section mt-5 pt-5">
      <h1 className="poetsen-one-regular">
        ASSOCIATION OF BRITISH SCHOLARS : HYDERABAD CHAPTER
      </h1>

      {/*   <div className="container">    */}
      <div className="card-container">
        <img
          src="abshyd1.webp"
          alt="Association Logo"
          className="card-logo"
          width="90"
          height="90"
        />

        <div className="card-content">
          <h2 className="inter-400">
            Welcome to ABS Hyderabad Chapter : Connecting Intellectuals,
            Building Futures
          </h2>
          <p className="inter-400">
            The Association of British Scholars (ABS) Hyderabad Chapter is a dynamic organisation fostering a vibrant network for Indian professionals with UK education. It goes beyond a typical membership, organizing workshops, seminars and facilitates knowledge exchange. The members are encouraged to get updated on industry, education, research in their advancements, Collaborations and innovations.
          </p>
          <p className="inter-400">
            The returnly Scholars share their knowledge, experience gained from United Kingdom for the benefit of individuals, society in our Country.
          </p>
          <p className="inter-400">
            ABS Hyderabad Chapter also bridges the cultural gap between India and the UK. Social events and discussion meetings promote mutual understanding and appreciation. This enriches members lives and fosters globally connected community, They inturn contribute to a more vibrant and interconnected Hyderabad.
          </p>{" "}
          <br />
        </div>
      </div>
      {/* </div>*/}
    </section>
  );
};

export default Home;
