import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="https://www.tinyurl.com/abshyderabad">
          <img src="abshyd1.webp" alt="ABS-HYD Logo" width="50" height="50" />
          <span className="poetsen-one-regular brand-text">ABS-Hyderabad Chapter</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="#home" onClick={closeNavbar}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#ExecutiveBoard"
                onClick={closeNavbar}
              >
                Managing Committee
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#events" onClick={closeNavbar}>
                Events
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#membership" onClick={closeNavbar}>
                Membership
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact-us" onClick={closeNavbar}>
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
