import React from "react";
import "./ExecutiveBoard.css";

const ExecutiveBoard = () => {
  return (
    <section id="ExecutiveBoard" className="section">
      <h1 className="poetsen-one-regular">Managing Committee</h1>
      <section id="executive-board" className="leadership-section">
        <div className="leadership-table-container">
          <table className="leadership-table">
            <thead>
              <tr>
                <th>Role</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="leadership-role">President</td>
                <td>Sri R. Vijaynandan Reddy</td>
              </tr>
              <tr>
                <td className="leadership-role">Vice-President</td>
                <td>Sri Harsha Bathula</td>
              </tr>
              <tr>
                <td className="leadership-role">Secretary</td>
                <td> Sri Chakravarthi JNBK</td>
              </tr>
              <tr>
                <td className="leadership-role">Treasurer</td>
                <td>Sri Vincent</td>
              </tr>
              <tr>
                <td className="leadership-role">Joint Secretary</td>
                <td>Sri G Nagaraju</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section id="executive-committee" className="leadership-section">
        <h4 className="poetsen-one-regular">Executive Committee</h4>
        <div className="leadership-table-container">
          <table className="leadership-table">
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dr. TRK Chetty</td>
              </tr>
              <tr>
                <td>Sri AB Daniel</td>
              </tr>
              <tr>
                <td>Sri CLN Gandhi</td>
              </tr>
              <tr>
                <td>Dr. T. Harinarayana</td>
              </tr>
              <tr>
                <td>Prof. N. Laxman Rao</td>
              </tr>
              <tr>
                <td>Dr. TVM Murthy</td>
              </tr>
              <tr>
                <td>Sri Kilaru Rama Rao</td>
              </tr>
              <tr>
                <td>Sri Umapathi Sattaru</td>
              </tr>
              <tr>
                <td>Prof. Zia Husam</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </section>
  );
};

export default ExecutiveBoard;
