import React, { useState, useEffect } from "react";
import "./Events.css";

const Events = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const events = [
    {
      title: "Team ABS Meet",
      description: "Team ABS Meet",
      images: [
        require("../media/images/e1.webp"),
        require("../media/images/e3.webp"),
        require("../media/images/e4.webp"),
      ],
    },
    {
      title: "EC meeting 1",
      description: " EC meeting 1",
      images: [
        require("../media/images/ecm1.webp"),
        require("../media/images/ecm2.webp"),
        require("../media/images/ecm3.webp"),
        require("../media/images/ecm4.webp"),
        require("../media/images/ecm5.webp"),
        require("../media/images/ecm6.webp"),
      ],
    },
    {
      title: "EC meeting 2",
      description: " EC meeting 2",
      images: [
        require("../media/images/past1.webp"),
        require("../media/images/past2.webp"),
      ],
    },
    {
      title: "EC Meeting 3",
      description: "EC meeting.",
      images: [require("../media/images/ecm.webp")],
    },
    {
      title: "Quiz for School Students",
      description: "Quiz for School Students.",
      images: [require("../media/images/quiz.webp")],
    },
  ];

  useEffect(() => {
    function handleResize() {
      if (modalOpen) {
        const modalHeight =
          document.querySelector(".modal-content").offsetHeight;
        const windowHeight = window.innerHeight;
        if (modalHeight > windowHeight) {
          document.querySelector(".modal-content").style.top = `${window.scrollY + 80
            }px`;
        } else {
          document.querySelector(".modal-content").style.top = `${(windowHeight - modalHeight) / 2 + 80
            }px`;
        }
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [modalOpen]);

  const handleCardClick = (event) => {
    setSelectedEvent(event);
    setCurrentImageIndex(0);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  const handleNextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % selectedEvent.images.length
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedEvent.images.length - 1 : prevIndex - 1
    );
  };

  return (
    <section id="events" className="section">
      <h1 className="poetsen-one-regular">Events</h1>

      <div className="events-section">
        <h2 className="events-heading">Upcoming Events</h2>
        <div className="events-container">
          {events
            .filter(
              (event) =>
                event.title === "EC Meeting 3" ||
                event.title === "Quiz for School Students"
            )
            .map((event, index) => (
              <div
                key={index}
                className="event-card"
                onClick={() => handleCardClick(event)}
              >
                {event.images.length > 0 && (
                  <img
                    src={event.images[0]}
                    alt={event.title}
                    className="event-image"
                  />
                )}
                <h3 className="event-title">{event.title}</h3>
              </div>
            ))}
        </div>
      </div>

      <div className="events-section">
        <h2 className="events-heading">Past Events</h2>
        <div className="events-container">
          {events
            .filter(
              (event) =>
                event.title === "Team ABS Meet" ||
                event.title === "EC meeting 1" ||
                event.title === "EC meeting 2"
            )
            .map((event, index) => (
              <div
                key={index}
                className="event-card"
                onClick={() => handleCardClick(event)}
              >
                {event.images.length > 0 && (
                  <img
                    src={event.images[0]}
                    alt={event.title}
                    className="event-image"
                  />
                )}
                <h3 className="event-title">{event.title}</h3>
              </div>
            ))}
        </div>
      </div>

      {modalOpen && selectedEvent && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={handleCloseModal}>
              CLOSE
            </button>
            <div className="image-gallery">
              <button className="nav-button left" onClick={handlePrevImage}>
                ◀
              </button>
              <img
                src={selectedEvent.images[currentImageIndex]}
                alt={`${selectedEvent.title} ${currentImageIndex + 1}`}
                className="gallery-image"
              />
              <button className="nav-button right" onClick={handleNextImage}>
                ▶
              </button>
            </div>
            <div className="modal-description">
              <h3>{selectedEvent.title}</h3>
              <p className="description-scroll">{selectedEvent.description}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Events;
