import React from "react";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <section id="contact-us" className="contact-section">
      <h1 className="poetsen-one-regular">Contact Us</h1>
      {/* don't change code till this point */}
      <div className="contact-cards">
        <div className="contact-card">
          <img src="p.webp" alt="Card 1" className="contact-card-image" />
          <div className="contact-card-body">
            <h2 className="contact-card-title">Contact us</h2>
            <div className="contact-info">
              <div>
                <span>President </span>
                <a href="tel:9246540446">
                  <i className="fas fa-phone">9246540446</i>
                </a>
              </div>
              <div>
                <span>Vice President</span>
                <a href="tel:9492324321">
                  <i className="fas fa-phone">9492324321</i>
                </a>
              </div>
              <div>
                <span>Secretary</span>
                <a href="tel:9490309900">
                  <i className="fas fa-phone">9490309900</i>
                </a>
              </div>
              <div>
                <span>Treasurer</span>
                <a href="tel:9182607414">
                  <i className="fas fa-phone">9182607414</i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-card">
          <img src="e.webp" alt="Card 2" className="contact-card-image" />
          <div className="contact-card-body">
            <h2 className="contact-card-title">Email</h2>
            <div className="contact-info">
              <br /> <br />
              E-mail us [at] <br /> <br /> <br />
              <a href="mailto:abshyd2024@gmail.com">
                <i className="fas fa-envelope">abshyd2024@gmail.com</i>
              </a>
            </div>
          </div>
        </div>
        <div className="contact-card">
          <img src="o.webp" alt="Card 3" className="contact-card-image" />
          <div className="contact-card-body">
            <h2 className="contact-card-title">Visit</h2>
            <div className="contact-info">
              <br /> <br /> ABS HYDERABAD OFFICE
              <br /> <br /> <br />
              <a href="https://maps.app.goo.gl/45Y3EqyzQPBHh1fY6">
                <i className="fas fa-map-marker-alt">View Location</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
