import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import ExecutiveBoard from "./components/ExecutiveBoard";
import Events from "./components/Events";
import Membership from "./components/Membership";
import ContactUs from "./components/ContactUs";
import "./App.css";
import AssociationPage from "./components/AssociationPage";

const App = () => {
  return (
    <div>
      <Navbar />
      <Home />
      <ExecutiveBoard />
      <Events />
      <Membership />
      <ContactUs />
      <AssociationPage />
    </div>
  );
};

export default App;
